@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');

html, body {
    -webkit-animation: fadein 1.3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1.3s; /* Firefox < 16 */
    -o-animation: fadein 1.3s; /* Opera < 12.1 */
    animation: fadein 1.3s;
    overflow-x: hidden;
    width: 100%;
    float: left;
    height: 100%;
    font-family: 'Poppins', sans-serif;
}

*:focus {
    outline: none;
    box-shadow: none !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: fixed;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.sr-only {
    display: none !important
}

.searchBar {
    float: left;
    width: 100%;
}

.react-bs-table-sizePerPage-dropdown {
    float: left;
}

button:focus, select:focus, input:focus {
    box-shadow: none !important;
}

.priceFilter button {
    display: block;
    margin: 5px auto;
    font-size: 10px;
    font-family: 'DM Sans', sans-serif;
    background: #B61D35;
    border: none
}

.priceFilter input:first-child {
    margin-right: 5px;
    margin-right: 5px;
}

.priceFilter input {
    max-width: 140px;
    float: left;
    height: 25px;
    margin-bottom: 5px;
}

.priceFilter span {
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
}

.search-label input {
    height: 50px;
    float: right;
    max-width: 280px;
    width: 100%;
    border-color: #B6D0E2;
}

.table-responsive tbody tr td {
    color: #000 !important;
    text-align: left;
    padding: 5px 15px;
    font-size: 15px;
    word-wrap: break-word
}

.table-responsive thead tr th:last-child, .table-responsive tbody tr td:last-child {
    text-align: center;
}

.table-responsive thead tr th {
    padding: 10px 15px;
    vertical-align: middle;
    text-align: left;
    font-size: 16px;
}

.table-responsive {
    clear: both;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
}

.search-label {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.table > :not(:first-child) {
    border-top: 2px solid #B6D0E2 !important
}

.ate-filter-comparator {
    margin-right: 5px;
}

.form-control {
    font-size: 13px !important;
}

.custom-row-class {
    vertical-align: middle;
    color: #3b3b3b
}

.table th {
    font-weight: normal;
}

.page-item.active .page-link {
    background: #0096FF !important;
}

.pagination li a {
    font-family: 'DM Sans', sans-serif;
    padding: 8px 18px;
    color: #000
}

li.page-item {
    border: 1px solid #B6D0E2
}

.date-filter label {
    margin-right: 5px;
}

.table tbody th {
    vertical-align: middle;
}

.dropdown-item a {
    text-decoration: none;
    color: #3b3b3b;
    display: block
}

html body .single-container h6 {
    font-weight: 400;
    margin-top: 30px;
    font-family: 'DM Sans', sans-serif;
    color: #7393B3;
    font-size: 1.2rem;
}

html body .single-container h4 {
    margin: 10px 0 10px 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 30px;
}

html body .single-container {
    text-align: left;
}

html body .alert-dark {
    padding: 0.5rem;
    border: 1px solid #B6D0E2;
    font-family: 'DM Sans', sans-serif;
    color: #000;
    font-size: 15px;
    background: #fff;
}

/* Login Form Style */

.wrap-login h1 {
    margin-bottom: 20px;
}

.wrap-login {
    width: 390px !important;
    margin: 10% auto auto auto !important;
}

.wrap-login a {
    margin-top: 20px;
    display: block;
    font-size: 10px
}

.wrap-login button:hover {
    background-color: #333333;
    box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

.wrap-login button {
    margin-top: 30px;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background-color: #57b846;
    border-radius: 25px;

    box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.ResetPassword button {
    margin-top: 20px;
}

.ResetPassword {
    max-width: 320px;
    margin: 5% auto;

}

.MuiInput-underline:after {
    border-bottom: 2px solid #57b846 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #57b846 !important;
}

.MuiInputBase-input {
    padding: 10px;
}

.MuiFormControl-root {
    width: 100%;
}

.sign-out-wrap button:hover {
    background: none;
    box-shadow: none;
}

.sign-out-wrap button {
    box-shadow: none;
    background: #fff;
    padding: 7px 20px;
    font-size: 15px;
    font-family: 'roboto', sans-serif;
    color: #000;
    text-transform: capitalize;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #000

}

.page-item:first-child .page-link {
    border-radius: 0.25rem;
}

body .page-item.active .page-link, .page-link {
    border: none !important;
    color: #fff;
    border-radius: 0

}

body .pagination > li:first-child {
    border-radius: 5px 0 0 5px;
}

body .pagination > li:last-child {
    border-radius: 0 5px 5px 0;
}


/*NAV*/
body .nav-item a {
    font-family: 'Roboto', sans-serif;
    color: #000;
    font-size: 16px;
    padding: 7px 20px;
    text-decoration: none;
    margin-right: 20px
}

body .navbar-nav {
    margin-left: 20px;
}

body .navbar {
    padding: 15px 0;
    background: #fff !important;
    border-bottom: 1px solid #B6D0E2;
}

body .nav-item a.active {
    border-radius: 5px;
    padding: 10px;
    background-color: #B61D35;
    color: #fff !important
}

body .info img {
    position: absolute;
    left: 10px;
    top: 12px
}

body .info:hover {
    color: #fff
}

body .info {
    background: #0096FF;
    padding: 10px 25px 10px 40px;
    color: #fff;
    font-size: 13px;
    font-family: 'DM Sans', sans-serif;
    position: relative;
}


body #pageDropDown::after {
    right: 10px;
    top: 15px;
    position: absolute;
}

body #pageDropDown {
    background: none;
    color: #000;
    padding: 5px 50px 5px 10px;
    text-align: left;
    position: relative;
    display: block;
    width: 100px;
}

.footer {
    padding: 20px 0;
    color: #7393B3;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
}

body .container-fluid {
    width: 97%;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {

    body .react-bootstrap-table table {
        table-layout: auto;
    }

    .search-label input {
        max-width: none;
    }

    body .navbar-nav {
        margin-top: 20px;
    }

    body .pagination {
        float: left;
        margin-top: 20px;
    }

    body .single-container {
        width: 100%;
    }

}

.primary-button:hover {
    background: #40B5AD;
}

.primary-button {
    background: #40826D;
    font-family: 16px;
    color: #ffffff;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    margin: 10px;
    margin-left: 0;
    float: left
}

.react-datepicker-wrapper input {
    float: left;
    max-width: 250px;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid grey;
    margin-right: 10px;
}

.date-filter button {
    float: right;
    margin-top: 20px;
}

.date-filter {
    float: right;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 8px;
}

.home-container img {
    width: 100%;
}

.home-container {
    max-width: 800px;
    width: 100%;
    margin: auto;
}

.react-datepicker-wrapper {
    width: auto;
}

.picker {
    margin-right: 10px;

}

.picker h6 {
    font-size: 10px;
    margin: 0 0 5px 0;
    text-align: left;
}

.download-play-button img {
    width: 100%;
}

.download-play-button {
    border: none;
    background: none;
    width: 45px;
    height: 45px;
    float: right;

}

.creative-video {
    width: 100%;
}